body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex: 1;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a > button {
  min-height: 40px;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.faicon {
  width: 35px;
  height: 35px;
}

/**
 * Custom Nerd UI styles
 */

/* TODO: move to isolated defualt theme */
.nerdUI .nerdUI--label {
  font-size: 0.875rem;
}

.canvas {
  background-color: rgba(240, 247, 255, 0.5);
  background-clip: padding-box;
  background-image: radial-gradient(
      at 51% 52%,
      rgba(240, 247, 255, 0.5) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, rgb(255, 255, 255) 0px, transparent 20%),
    radial-gradient(at 0% 95%, rgba(194, 224, 255, 0.3), transparent 40%),
    radial-gradient(at 0% 20%, rgb(240, 247, 255) 0px, transparent 50%),
    radial-gradient(at 93% 85%, rgba(194, 224, 255, 0.2) 0px, transparent 50%),
    url("https://storage.googleapis.com/rhapsody/canvas.svg");
}

.archercanvas2 {
  background-color: rgb(0, 30, 60);
  background-clip: padding-box;
  background-image: radial-gradient(
      at 51% 52%,
      rgba(19, 47, 76, 0.5) 0px,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, rgb(19, 47, 76) 0px, transparent 50%),
    radial-gradient(at 0% 95%, rgb(23, 58, 94) 0px, transparent 50%),
    radial-gradient(at 0% 20%, rgb(23, 58, 94) 0px, transparent 35%),
    radial-gradient(at 93% 85%, rgba(30, 73, 118, 0.8) 0px, transparent 50%),
    url("https://storage.googleapis.com/rhapsody/canvas.svg");
}

.whiteArrowcanvas2 {
  background-color: #212121;
  background-clip: padding-box;
  background-image: radial-gradient(at 51% 52%, #424242 0px, transparent 50%),
    radial-gradient(at 80% 0%, #424242 0px, transparent 50%),
    radial-gradient(at 0% 95%, #424242 0px, transparent 50%),
    radial-gradient(at 0% 20%, #424242 0px, transparent 35%),
    radial-gradient(at 93% 85%, #424242 0px, transparent 50%),
    url("https://storage.googleapis.com/rhapsody/canvas.svg");
}

.container {
  width: 200px;
  height: 150px;
}

.item {
  overflow: visible;
  stroke: #fff;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}
